import logo from '../images/bee.svg';

export default function TempPage() {
    return (
        <div className="master-grid">
            <article>
                <section class="app-header">
                    <h3 class="centered app-name">Honey Hole Club LLC</h3>
                    <img class="app-logo" src={logo} alt="Honey Hole Club LLC Logo" />
                </section>
                <section class="centered">[ <b>Site Under Construction</b> ]</section>
                <section class="centered">[ <a href="docs/shh_membership.pdf"><b>Membership Information</b></a> ]</section>
                <section>
                    <strong>Honey Hole Club LLC</strong> provides a location, equipment and event coordination for anyone interested in learning, playing, and/or competing in cornhole. The flagship club 
                    &nbsp;<strong>Seaway Honey Holes</strong> is located at the Grange in Rensselaer Falls, NY. Anyone is welcome to come play in one of the many public events we will be hosting each and every week. 
                    New to the game? We have plans in the new year to have <i>at least</i> one day a week that is dedicated to members who may not want to compete. Our goal is to provide a service to as many
                    people in the community as possible within our means. Stay tuned to this site as well as our the club <a href="https://www.facebook.com/seawayhoneyholes" alt="Seaway Honey Holes Facebook Page">Facebook page</a> 
                    &nbsp;for up to date details.<br/><br/>
                </section>
            </article>
        </div>
    );
}